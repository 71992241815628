.OrdersPerMonthCharts {
  .loadingContainer {
    min-height: 150px;
  }

  .custom-tooltip {
    background-color: #fff;
    border: solid 1px #cbcbcb;
    padding: 10px;

    .tooltip {
      &-title {
        font-size: 16px;
        text-transform: capitalize;
      }
    }
  }
}