.IncreasesBox {
  text-align: center;
  width: 300px;
  padding: 15px 10px;

  @media (max-width: 1024px) {
    width: 240px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  .imageContainer {
    border-radius: 4px;
    height: 80px;
    width: 80px;
    padding: 15px;
    transition: all 0.3s;
    box-shadow: 0 14px 26px -12px rgba(103,58,183,0.42),0 4px 23px 0px rgba(0,0,0,0.12),0 8px 10px -5px rgba(103,58,183,0.2);

    &:hover {
      height: 90px;
      width: 90px;
    }
  }

  .title {
    font-family: Noto Sans TC,sans-serif;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    font-weight: 600;
    color: #232323;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  .description {
    line-height: 22px;
  }
}