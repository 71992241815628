.NumberBox {
  flex: 1 1 150px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &-content {
    min-height: 160px;
  }

  @media (max-width: 1030px) {
    width: 100%;
  }

  .title {
    text-align: center;
  }

  .Polaris-Card {
    .value {
      height: 60px;
      font-size: 36px;
    }
  }
}
