.MainSection {
  position: relative;
  padding: 20px 0 80px 0;
  color: #fff;

  .shopifyAppStoreButton {
    max-height: 75px;
    width: 245px;
  }

  .bannerSection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px;
    background-color: transparent;
    background-image: linear-gradient(180deg, #27A3FC, #12c7f5);
    transition: background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
  }

  .bannerMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: fill;
    height: 100%;

    @media (max-width: 1024px) {
      object-fit: cover;
    }
  }

  .carouselContainer {
    width: 80%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .carousel {
    .slide {
      background: transparent;
    }

    .control-dots {
      bottom: -12px;

      .dot {
        background: #27A3FC;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 20px 0 50px 0;
  }

  .MainSection--content {
    padding: 120px 15px 0 15px;
    position: relative;
    z-index: 1;

    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
  }

  .content-text {
    padding: 10px;
  }

  .title {
    font-family: Noto Sans TC,sans-serif;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: -1.2px;
    text-shadow: 0 0 10px rgba(0,0,0,.2);
    mix-blend-mode: screen;
    line-height: 1.2;

    @media (max-width: 767px) {
      font-size: 35px;
      letter-spacing: -0px;
    }

    @media (max-width: 480px) {
      font-size: 32px;
    }

  }

  .description {
    font-family: Noto Sans TC,sans-serif;
    font-size: 22px;
    font-weight: 100;
    letter-spacing: -1.2px;
    line-height: 1.2;
  }

  .preview-devices {
    width: 100%;
  }

  svg {
    position: absolute;
    bottom: -1;
    width: calc(129% + 1.3px);
    height: 103px;
    transform: translateX(-50%);
    left: 50%;

    @media (max-width: 767px) {
      width: calc(195% + 1.3px);
      height: 50px;
    }
  }
}
