.AdminExternalBanner {
  background-color: #5C6AC4;

  color: #fff;
  font-weight: 500;
  cursor: pointer;

  &.isOutside {
    background-color: #BF0711;
  }
}