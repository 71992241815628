.container {
  background-color: #fff;
}

.title {
  text-align: center;
  padding-top: 10px;
  font-size: 20px;
  line-height: 24px;
}

.subtitle {
  text-align: center;
  padding-top: 10px;
  font-size: 16px;
  line-height: 24px;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}