.DemoSection {
  margin-bottom: 60px;

  .previewApp {
    max-width: 50%;

    @media (max-width: 767px) {
      max-width: 100%;
      margin-top: 20px;
    }
  }

  .paddingRight {
    padding-right: 40px;

    @media (max-width: 767px) {
      padding-right: 0;
    }
  }

  .paddingLeft {
    padding-left: 40px;

    @media (max-width: 767px) {
      padding-left: 0;
    }
  }

  .marginTop {
    margin-top: 80px;

    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }

  @media (max-width: 767px) {
    .col-5, .col-7 {
      width: 100%;
    }

    .boxContent {
      margin-top: 30px;
    }
  }
}