.OnBoarding {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(#000, 0.3);
  z-index: 40;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  cursor: default;

  .OnBoarding--Card {
    border-radius: 3px;
    border: 1px solid rgba(#000, 0.1);
    padding: 1rem;
    box-shadow: rgba(#000, 0.2) 0 3px 20px;
    max-width: 800px;
    height: 650px;
    flex-grow: 1;
    background-color: #f4f6f8;
    overflow: auto;

    display: flex;
    flex-direction: column;

    transition: 100ms linear background;
  }

  .OnBoarding--Nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    height: 50px;
  }

  .OnBoarding--Arrow {
    &.invisible {
      visibility: hidden;
    }
  }

  .OnBoarding--Bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4rem;
  }

  .OnBoarding--Bullet {
    width: 18px;
    height: 18px;
    background: #dedede;
    border-radius: 50%;
    cursor: pointer;
    border: 3px solid transparent;
    & + .OnBoarding--Bullet {
      margin-left: 1rem;
    }
    &.active {
      background: #3B7AEE;
    }
    &.current {
      border-color: white;
    }
  }

  .OnBoarding--Content {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-grow: 1;

    .Modal-fields {
      form {
        display: flex;
        flex-direction: row;

        .Input {
          flex-grow: 1;
        }

        .Modal-submit {
          display: flex;
        }
      }
    }
  }
}
