.ColorPicker {
  .previewColor {
    width: 35px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px #ddd;
    cursor: pointer;
  }

  .isDisabled {
    opacity: 0.5;
  }

  .picker {
    position: absolute;
    left: -62px;
    z-index: 1000;
  }
}
