.StepsManualInstallation {
  .content {

    .tableHtmlTag {
      border-collapse: collapse;

      th, td {
        border-bottom: 1px solid #ddd;
        padding: 4px;
      }

      thead {
        td {
          font-weight: bold;
        }
      }
    }
  }

  .link {
    color: #006fbb;
    text-decoration: none;
  }
}