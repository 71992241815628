.OrderConfirmationSettingsForm {
  .Polaris-Card {
    overflow: unset;
  }

  .labelField {
    width: 100%;
    max-width: 250px;
  }
}
