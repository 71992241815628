.OffersPage {
  ion-icon {
    font-size: 22px;
  }

  .tips {
    color: #007ACE;
  }

  .createOfferButton {
    text-decoration: none;
  }
}