.hint_base {
  content: attr(aria-label);
  opacity: 0;
  position: absolute;
  z-index: 5000;
  pointer-events: none;
  padding: 8px 10px;
  line-height: 15px;
  white-space: nowrap;
  text-decoration: none;
  text-indent: 0;
  overflow: visible;
  font-size: 12px;
  color: #fff;
  text-shadow: 1px 0 1px #888;
  background-color: #383838;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);

  &:before {
    content: '';
    position: absolute;
    background: 0 0;
    border: 6px solid transparent;
    z-index: 1000001;
  }
}

[aria-label].hint--top {
  &:after {
    @extend .hint_base;
    top: -100%;
    left: -25%;
  }

  &:hover:after,
  &:focus:after {
    transform: translateY(-8px);
  }
}

[aria-label].hint--left {
  &:after {
    @extend .hint_base;
    top: -3px;
    right: 110%;
  }

  &:hover:after,
  &:focus:after {
    transform: translateX(-8px);
  }
}

[aria-label].hint--right {
  &:after {
    @extend .hint_base;
    top: -3px;
    left: 110%;
  }

  &:hover:after,
  &:focus:after {
    transform: translateX(8px);
  }
}

/* NOTE: not(.Polaris-Frame__NavigationDismiss) to avoid conflicts with Shopify mobile navigation menu */
[aria-label]:not(.Polaris-Frame__NavigationDismiss):not(.Polaris-ResourceList-Item__Button) {
  position: relative;
}

[aria-label]:hover:after,
[aria-label]:focus:after {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.3s ease, -moz-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
}
