.LoadingPage {
  min-height: 600px;

  .loader {
    display: inline-block;
    position: relative;
    width: 160px;
    height: 160px;
  }

  .loader div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: calc(13px * 3);
    background: #fff;
    animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  .loader div:nth-child(1) {
    animation-delay: -0.24s;
    background-color: #B3BCF5;
  }

  .loader div:nth-child(2) {
    left: calc(20px * 3);
    animation-delay: -0.12s;
    background-color: #B3BCF5;
  }

  .loader div:nth-child(3) {
    left: calc(38px * 3);
    animation-delay: 0;
    background-color: #B3BCF5;
  }

  @keyframes loader {
    0% {
      top: 6px;
      height: calc(51px * 3);
    }
    50%, 100% {
      top: 19px;
      height: calc(26px * 3);
    }
  }
}
