.shopify-link {
  color: #006fbb;
  text-decoration: none;
}

.link--silent {
  text-decoration: none;
  &:visited {
    color: unset;
  }
}

.title {
  font-size: 2.8rem;
  line-height: 3.2rem;
}

.subtitle {

}

pre,
code {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 14px;
  font-weight: 100;
  border-radius: 3px;
  color: #333;
  border: solid 1px #dedede;
  padding: 4px;
}

pre {
  overflow-x: hidden;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1px;
}

.uppercase {
  text-transform: uppercase;
}

/*
TEXT
*/

.xl-text {
  font-size: 42px;
  line-height: 44px;
  font-weight: 500;
}

.l-text {
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
}

.m-text {
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
}

.s-text {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

strong,
.bold {
  font-weight: 700;
}


.fw-100 {
  font-weight: 100;
}

.fw-500 {
  font-weight: 500;
}

.regular {
  font-weight: normal;
}

em,
.italic {
  font-style: italic;
}

.caps {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.underline {
  text-decoration: underline;
}

.underline-hover:hover {
  text-decoration: underline;
}

.code {
  font-family: 'Source Code Pro', Consolas, monospace;
}

/*
Alignement
*/
.left-align {
  text-align: left;
}

.center {
  text-align: center;
}

.right-align {
  text-align: right;
}

.justify {
  text-align: justify;
}

.sm-center {
  @media screen and (max-width: 40rem) {
    text-align: center;
  }
}
