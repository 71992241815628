.as {

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 3.5rem);
  }

  &-modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 32px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99999999999;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    animation: show .5s ease;
  }

  &-content {
    position: relative;
    width: 100%;
    min-width: 320px;
    max-width: 840px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    @media (min-width: 576px) {
      // width: 512px;
    }

    img {
      max-width: 100%;
    }
  }

  &-close-button {
    position: absolute;
    top: -12px;
    right: -12px;
    background: #222;
    width: 27px;
    min-width: 27px;
    height: 27px;
    min-height: 27px;
    padding: 0;
    border: solid 2px #eee;
    cursor: pointer;
    outline: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 50%;

    &:hover {
      opacity: 0.8;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      top: 10px;
      left: 6px;
      width: 12px;
      height: 2px;
      background-color: #eee;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover:before, &:hover:after {
      background-color: #bdbdbd;
    }

  }
}

// Fade-in open animation
@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}