.NavigationTopBar {
  background-color: #27A3FC;
  color: #fff;
  height: 80px;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease 0s;

  &.isLoginPage {
    background-color: #202e78;
    height: 60px;
  }

  &.isSticky {
    height: 56px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);

    .navigationButton {
      color: #555555 !important;
    }
  }

  .logo {
    width: 40px;
    transition: all 0.3s ease 0s;

    &.isSticky {
      width: 30px;
    }
  }

  .logoText {
    max-height: 35px;
  }

  .linksContainer {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 8px 0;
    padding: 0;

    li {
      display: flex;
      margin: 0 10px;
    }

    .navigationButton {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      padding: 10px 22px;
      text-transform: uppercase;
      letter-spacing: 0.9px;
      cursor: pointer;
      width: 100%;
      text-align: center;

      @media (max-width: 991px) {
        font-size: 14px;
        padding: 10px 5px;
        letter-spacing: 0;
      }

      @media (max-width: 768px) {
        font-size: 16px;
        padding: 10px 22px;
        letter-spacing: 0.9px;
      }

      &.active {
        opacity: 0.5;
      }
    }

    &.mobile {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 56px;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 1000;
      margin: 0;
      max-height: 0;
      transition: max-height 0.3s;
      overflow: hidden;

      li {
        font-size: 14px;
      }

      li:first-child {
        margin-top: 10px;
      }

      li:last-child {
        margin-bottom: 10px;
      }

      a {
        color: #232323;
      }

      &.open {
        max-height: 300px;
      }

      .navigationButton {
        &.active {
          border-bottom: 0;
        }
      }
    }
  }

  .loginButton {
    background-color: #75C6FF;
    border-radius: 3px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 10px 22px;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    cursor: pointer;
    text-decoration: none;
    display: block;
    width: fit-content;

    &:hover {
      background-color: #5FBDE8;
    }

    &.isLoginPage {
      background-color: #47c1bf;

      &:hover {
        background-color: #47a5a3;
      }
    }
  }

  .menuIcon {
    padding: 10px;
    cursor: pointer;
  }
}
