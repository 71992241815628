.uppercase {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}
.overflow-hidden {
  overflow: hidden;
}
.box-shadow {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}
.box-shadow-02 {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
}

.box-shadow-card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.bold {
  font-weight: bold;
}

.fontLight {
  font-weight: 100;
}

/*
Margin

.m1         - change the number with [0,1,2,3,4]
.mt1        - change the number with [0,1,2,3,4]
.mr1        - change the number with [0,1,2,3,4]
.mb1        - change the number with [0,1,2,3,4]
.ml1        - change the number with [0,1,2,3,4]
.mx-auto    - center a block
.sm-mx-auto - center a block when (max-width: 40em)
.ml-auto    - margin left auto

*/

.m0 {
  margin: 0;
}
.mt0 {
  margin-top: 0;
}
.mr0 {
  margin-right: 0;
}
.mb0 {
  margin-bottom: 0;
}
.ml0 {
  margin-left: 0;
}

.mt1n + .mt1n {
  margin-top: 8px;
}
.ml1n + .ml1n {
  margin-left: 8px;
}
.mr1n + .mr1n {
  margin-right: 8px;
}
.mb1n + .mb1n {
  margin-bottom: 8px;
}

.m05 {
  margin: 4px;
  margin: 0.25rem;
}
.mt05 {
  margin-top: 4px;
  margin-top: 0.25rem;
}
.mr05 {
  margin-right: 4px;
  margin-right: 0.25rem;
}
.mb05 {
  margin-bottom: 4px;
  margin-bottom: 0.25rem;
}
.ml05 {
  margin-left: 4px;
  margin-left: 0.25rem;
}

.m1 {
  margin: 8px;
  margin: 0.5rem;
}
.mt1 {
  margin-top: 8px;
  margin-top: 0.5rem;
}
.mr1 {
  margin-right: 8px;
  margin-right: 0.5rem;
}
.mb1 {
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
}
.ml1 {
  margin-left: 8px;
  margin-left: 0.5rem;
}

.m2 {
  margin: 16px;
  margin: 1rem;
}
.mt2 {
  margin-top: 16px;
  margin-top: 1rem;
}
.mr2 {
  margin-right: 16px;
  margin-right: 1rem;
}
.mb2 {
  margin-bottom: 16px;
  margin-bottom: 1rem;
}
.ml2 {
  margin-left: 16px;
  margin-left: 1rem;
}

.m3 {
  margin: 32px;
  margin: 2rem;
}
.mt3 {
  margin-top: 32px;
  margin-top: 2rem;
}
.mr3 {
  margin-right: 32px;
  margin-right: 2rem;
}
.mb3 {
  margin-bottom: 32px;
  margin-bottom: 2rem;
}
.ml3 {
  margin-left: 32px;
  margin-left: 2rem;
}

.m4 {
  margin: 64px;
  margin: 4rem;
}
.mt4 {
  margin-top: 64px;
  margin-top: 4rem;
}
.mr4 {
  margin-right: 64px;
  margin-right: 4rem;
}
.mb4 {
  margin-bottom: 64px;
  margin-bottom: 4rem;
}
.ml4 {
  margin-left: 64px;
  margin-left: 4rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}

@media screen and (max-width: 40em) {
  .sm-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

/*
Padding

.p1      - change the number with [0,1,2,3,4]
.py1     - change the number with [1,2,3,4]
.px1     - change the number with [1,2,3,4]

*/

.p0 {
  padding: 0;
}
.p1 {
  padding: 8px;
  padding: 0.5rem;
}
.py1 {
  padding-top: 8px;
  padding-top: 0.5rem;
  padding-bottom: 8px;
  padding-bottom: 0.5rem;
}
.px1 {
  padding-left: 8px;
  padding-left: 0.5rem;
  padding-right: 8px;
  padding-right: 0.5rem;
}

.p2 {
  padding: 16px;
  padding: 1rem;
}
.py2 {
  padding-top: 16px;
  padding-top: 1rem;
  padding-bottom: 16px;
  padding-bottom: 1rem;
}
.px2 {
  padding-left: 16px;
  padding-left: 1rem;
  padding-right: 16px;
  padding-right: 1rem;
}

.p3 {
  padding: 32px;
  padding: 2rem;
}
.py3 {
  padding-top: 32px;
  padding-top: 2rem;
  padding-bottom: 32px;
  padding-bottom: 2rem;
}
.px3 {
  padding-left: 32px;
  padding-left: 2rem;
  padding-right: 32px;
  padding-right: 2rem;
}

.p4 {
  padding: 64px;
  padding: 4rem;
}
.py4 {
  padding-top: 64px;
  padding-top: 4rem;
  padding-bottom: 64px;
  padding-bottom: 4rem;
}
.px4 {
  padding-left: 64px;
  padding-left: 4rem;
  padding-right: 64px;
  padding-right: 4rem;
}

.p8 {
  padding: 128px;
  padding: 8rem;
}
.py8 {
  padding-top: 128px;
  padding-top: 8rem;
  padding-bottom: 128px;
  padding-bottom: 8rem;
}
.px8 {
  padding-left: 128px;
  padding-left: 8rem;
  padding-right: 128px;
  padding-right: 8rem;
}

/*
Border
*/
.border {
  border-style: solid;
  border-width: 1px;
  border-color: #000000;
  border-color: rgba(0, 0, 0, 0.125);
}

.border-top {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.125);
}

.border-right {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: rgba(0, 0, 0, 0.125);
}

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.125);
}

.border-left {
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: rgba(0, 0, 0, 0.125);
}

.border-white {
  border-color: white;
}

.border-bold {
  border-style: solid;
  border-width: 3px;
  border-color: rgba(0, 0, 0, 0.07);
}

/*
Radius
*/
.rounded {
  border-radius: 3px;
}
.circle {
  border-radius: 50%;
}

.rounded-top {
  border-radius: 3px 3px 0 0;
}
.rounded-right {
  border-radius: 0 3px 3px 0;
}
.rounded-bottom {
  border-radius: 0 0 3px 3px;
}
.rounded-left {
  border-radius: 3px 0 0 3px;
}

.rounded-top-left {
  border-radius: 3px 0 0 0;
}
.rounded-top-right {
  border-radius: 0 3px 0 0;
}

/*
Responsive helper

Your standard radius class.

.sm-show          - : (min-width: 40em)
.md-show          - : (min-width: 52em)
.lg-show          - : (min-width: 64em)
.display-none     - :
.sm-hide          - : (max-width: 40em)
.md-hide          - : (max-width: 52em)
.lg-hide          - : (max-width: 64em)

*/
.sm-show,
.md-show,
.lg-show,
.xl-show {
  display: none !important;
}

@media (min-width: 40em) {
  .sm-show {
    display: block !important;
  }
}

@media (min-width: 52em) {
  .md-show {
    display: block !important;
  }
}

@media (min-width: 64em) {
  .lg-show {
    display: block !important;
  }
}

@media (min-width: 84em) {
  .xl-show {
    display: block !important;
  }
  .xl-min-hide {
    display: none;
  }
}

@media (max-width: 40em) {
  .sm-hide {
    display: none !important;
  }
}

@media (max-width: 52em) {
  .md-hide {
    display: none !important;
  }
}

@media (max-width: 64em) {
  .lg-hide {
    display: none !important;
  }
}

.display-none {
  display: none !important;
}
