.CreateOffer {
  .badge {
    background-color: #EBECF0;
    border-radius: 2em;
    color: #172B4D;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 1px;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center;
  }

  .Polaris-Card {
    overflow: visible;
  }
}