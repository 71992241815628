.VariantOptionsForm {
  .OptionContainer {
    margin-top: 1rem;

    .bigBorderLeft {
      border-left: 4px solid #dfe3e8;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 16px;
    }

    .tagsColorContainer {
      .labelVariantOption {
        font-weight: bold;
        white-space: nowrap;
        margin-right: 5px;
        margin-bottom: 4px;
      }
    }

    .optionTextField {
      z-index: 2;
    }
  }
}