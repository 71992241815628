.UpsellGeneralForm {
  .helpText {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-transform: none;
    text-transform: initial;
    letter-spacing: normal;
    letter-spacing: initial;
    color: #637381;
    margin-top: 0.4rem;
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: 0.1rem solid #dfe3e8;
  }

  .disabledLabel {
    color: #ababab;
  }

  .hintWarningContainer {
    color: #5C6AC4;
  }
}