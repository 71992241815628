.Homepage {
  overflow: hidden;
  background-color: #fff;
  color: #6f6f6f;
  font-weight: 400;

  .Sticky {
    background-color: #27A3FC;
  }

  .mainButton {
    background-color: #47C1BF;
    border-radius: 3px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 10px 22px;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    cursor: pointer;
    text-decoration: none;
    display: block;
    width: fit-content;

    &:hover {
      background-color: #47a5a3;
    }
  }

  .separator {
    width: 70%;
    border: none;
    box-shadow: 0px -4px 7px rgba(0,0,0, 0.05);
    height: 2px;
    background-image: linear-gradient(to right, white, #dddddd, white);
  }


  .sectionTitle {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .title {
      font-family: Noto Sans TC,sans-serif;
      font-weight: 700;
      font-size: 36px;
      line-height: normal;
      margin-bottom: 10px;
      padding: 0;
      letter-spacing: 0;
      color: #232323;

      @media (max-width: 991px) {
        font-size: 32px;
      }

      @media (max-width: 767px) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .subtitle {
      padding-top: 12px;
      font-size: 16px;
      line-height: 24px;
      word-spacing: 1px;
      margin-bottom: 32px;
      max-width: 680px;

      @media (max-width: 767px) {
        padding-top: 0;
      }
    }
  }
}