.PreviewUpsell {
  &--select {
    max-width: 350px;
  }

  &--container {
    border: solid 1px #dedede;
    border-radius: 5px;
    position: relative;

    .loadingContainer {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      opacity: 0.5
    }
  }
}
