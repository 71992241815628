$red-500: #DE3618;
$blue-500: #006FBB;
$teal-500: #50B83C;

.Toasts {
  position: fixed;
  z-index: 90;
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  user-select: none;

  .Toast + .Toast {
    margin-bottom: 16px;
  }

  .Toast {
    border: 3px solid rgba(#000, 0.2);
    border-radius: 3px;
    box-shadow: rgba(#000, 0.3) 0 3px 15px;
    background: white;
    display: flex;
    max-width: 300px;
    opacity: 1;
    animation: fade 0.25s linear;

    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    .Toast-icon {
      cursor: pointer;
      flex-shrink: 0;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .Toast-content {
      padding: 16px;
      user-select: text;
      border-radius: 3px;
    }

    &.info {
      border-color: $blue-500;

      .Toast-icon {
        background: $blue-500;
      }
    }

    &.error {
      border-color: $red-500;

      .Toast-icon {
        background: $red-500;
      }
    }

    &.success {
      border-color: $teal-500;

      .Toast-icon {
        background: $teal-500;
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}