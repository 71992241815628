.CheckAwesomeQuantityPopup {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 20px;
  z-index: 8000000;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateX(-300px);
  animation: slideIn 0.5s forwards;
  animation-delay: 1s;

  &.hidden {
    opacity: 0;
    transform: translateX(-300px);
    animation: slideOut 0.5s forwards !important;
  }

  .content {
    box-shadow: rgba(23, 24, 24, 0.1) -1px 0 20px 0;
    border-radius: 8px;
    padding: 10px 22px 10px 10px;
    box-sizing: border-box;
    min-width: 230px;
    color: rgba(33, 43, 54, 0.9);
    position: relative;
    background-color: #fff;
    line-height: 24px;

    .closeButton {
      position: absolute;
      right: 0;
      top: 0;
      margin: 8px;
      width: 12px;
      cursor: pointer;
    }

    a {
      font-weight: 500;
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-300px);
  }
}
