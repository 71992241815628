.productRow {
  border-radius: 10px;
  padding: 20px 6px;
  background-color: white;
  text-align: left;
  border-top: solid 1px #e6e6e6;

  @media (max-width: 700px) {
    text-align: center;
  }
}

.productName {
  margin-top: 8px;
  font-weight: bold;
}

.select {
  padding: 8px 28px 8px 10px;
  text-indent: 0.01px;
  width: 100%;
  font-size: 12px;
  display: block;
  border: 1px solid #e8e9eb;
  background-color: #fff;
  margin-bottom: 12px;
}

.containerSelect {
  position: relative;
  width: 100%;
}

.containerProductImage {
  max-width: 120px;
  max-height: 120px;

  img {
    object-fit: contain;
    max-width: 120px;
    max-height: 120px;
  }
}

.productImage {
  width: 100%;
}

.containerTitle {
  margin-bottom: 20px;
}

.containerProduct {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
}

.containerPrice {
  display: flex;
  margin-top: 8px;
  margin-bottom: 12px;

  @media (max-width: 700px) {
    justify-content: center;
  }
}

.price {
  color: #9ca0a6;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-decoration: line-through;
}

.discountPrice {
  margin-left: 10px;
  color: #e63235;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
}

.containerButtons {
  max-width: 340px;
  width: 100%;
  margin-left: 30px;
  margin-right: 20px;

  @media (max-width: 700px) {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.addToCart {
  margin-top: 8px;
  color: #fff;
  background-color: #e63235;
}

.btn {
  position: relative;
  vertical-align: middle;
  padding: 14px 28px;
  font-family: BrandonGrotesque,sans-serif;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 1px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(0,0,0,.06);
  transition: .3s cubic-bezier(.32,.36,.4,1);
}
