.TrialBanner {
  background-color: #47C1BF;

  color: #fff;
  font-weight: 500;
  cursor: pointer;

  &.isOutside {
    background-color: #BF0711;
  }
}