.container {
  padding: 10px;
  min-width: 180px;
  max-width: 400px;
  margin-top: 2px;
  position: relative;
}

.label {
  font-weight: 500;
  margin-bottom: 8px;
}

.variantRow:not(:last-child) {
  margin-bottom: 8px;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.option {
  padding: 2px 8px;
  border: solid 1px #666;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.selectedOption {
  background-color: red;
  color: #fff;
}

.swatchOptionContainer {
  padding: 3px;
  border: 1px solid #f2f2f2;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.swatchOption {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: center;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  image-rendering: -webkit-optimize-contrast;

  &:hover {
    opacity: 0.7;
  }
}

.isDisabled {
  opacity: 0.3 !important;
}

.swatchFirstLetter {
  font-weight: bold;
  color: #000;
}

.selectedSwatchOption {
  border-color: #000;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  padding: 0 8px;
  font-size: 12px;
  cursor: pointer;
}