.SettingsForm {
  .Polaris-Card {
    overflow: unset;
  }

  .labelField {
    width: 100%;
    max-width: 250px;
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: 0.1rem solid #dfe3e8;
  }
}
