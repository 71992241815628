.PreviewCartDiscountUpsell {
  background: #fff;
  padding: 20px;
  border: solid 1px #dedede;
  border-radius: 5px;
  text-align: right;
  font-size: 16px;
  line-height: 26px;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  .cart-subtotal__title {
    font-weight: bold;
  }

  .cart-subtotal {
    display: flex;
    justify-content: flex-end;
  }

  .cart-subtotal__title {
    font-size: 1.125em;
  }

  .cart-subtotal__price {
    padding-left: 55px;
    min-width: 150px;
    display: inline-block;
  }

  .btn {
    user-select: none;
    appearance: none;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 8px 15px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    white-space: normal;
    font-size: 14px;
  }

  .checkout {
    background-color: #557b97;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: #436076;
    }
  }

  .continue {
    color: #557b97;
    border-color: #557b97;

    &:hover {
      color: #436076;
      border-color: #436076;
    }
  }
}