.LabelField {
  width: 100%;
  max-width: 260px;

  .icon {
    margin-left: 4px;
  }

  &.isDisabled {
    opacity: 0.5;
  }

  .hiddenText {
    position: fixed;
    left: 0;
    top: -10;
    z-index: -1;
    height: 1px;
    width: 1px;
  }
}