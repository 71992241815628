.PlanBox {
  border-top: 10px solid #27A3FC;
  padding: 42px 20px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  border-radius: 10px;
  background-color: #fff;
  max-width: 270px;
  min-height: 560px;

  .title {
    font-weight: 700;
  }

  .price {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 6px;
    color: #27A3FC;
  }

  .description {
    color: #888;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .rows li {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    padding: 9px 0;
  }

  .installButton {
    color: #fff;
    background-color: #75C6FF;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 22px;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: block;
    width: 100%;

    &:visited {
      color: #fff;
    }
  }
}