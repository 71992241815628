.modal {
  border-radius: 10px;
  padding: 32px;
  background-color: white;
  text-align: center;
  overflow: hidden;
}

.title {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 1.2;
  color: #171c8e;
  font-family: BrandonGrotesque,sans-serif;
  font-weight: 700;
}

.subtitle {
  margin-bottom: 8px;
}


.containerTitle {
  margin-bottom: 20px;
}

.containerProduct {

}

.cancel {
  margin-top: 16px;
  color: #9ca0a6;
  cursor: pointer;
  opacity: 0.8;
}
