.TagTextField {
  width: 100%;

  &--label {
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }

  &--content {
    font-weight: 400;
    line-height: 2.4rem;
    position: relative;
    display: block;
    flex: 1 1;
    width: 100%;
    min-width: 0;
    min-height: 3.6rem;
    margin: 0;
    padding: 0.5rem 1.2rem;
    background: none;
    border: 0.1rem solid transparent;
    appearance: none;
    z-index: 0;

    &:not(.hasTags) {
      padding: 0.1rem 1.2rem;
    }

    .tagContainer:not(:last-child) {
      margin-right: 4px;
    }

    .backdrop {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 0.1rem solid var(--p-border, #c4cdd5);
      border-radius: var(--p-border-radius-base, 3px);
      box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        top: -0.2rem;
        right: -0.2rem;
        bottom: -0.2rem;
        left: -0.2rem;
        border: var(--p-override-none, 0.2rem solid #5c6ac4);
        border-radius: inherit;
        opacity: 0;
        transition: opacity 200ms cubic-bezier(0.64, 0, 0.35, 1);
        pointer-events: none;
      }

      &.isFocus {
        &:after {
          opacity: 1;
        }
      }
    }

    .fixMarginTop {
      margin-top: 4px;
    }

    .Polaris-Connected__Item--primary {
      .Polaris-TextField {
        input {
          padding: 0;
        }

        .Polaris-TextField__Backdrop {
          border: none;

          &:after {
            border: none;
          }
        }
      }
    }
  }
}