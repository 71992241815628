.Dashboard {
  .pleaseNote {
    font-style: italic;
    color: #919eab;
  }

  .marginBox {
    margin-bottom: 1rem;

    @media (min-width: 1410px) {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}