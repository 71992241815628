.AffiliationUrlCopy {
  .hiddenAffiliationUrl {
    position: absolute;
    z-index: -1;
    height: 1px;
    width: 1px;
  }

  .buttonCopy {
    width: 290px;
  }
}