.TabsCard {

  .description {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  &--container {
    display: flex;
    margin: -2.1rem;

    @media (max-width:990px) {
      display: block;
    }
  }

  .tabList {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border-left: .1rem solid #dfe3e8;
    border-right: .1rem solid #dfe3e8;
    list-style: none;
    background: #f4f6f8;
    min-height: 330px;

    @media (max-width: 990px) {
      flex-direction: row;
      margin: 0 0 0 -1px;
      border: 0;
      min-height: 0;
    }

    .tab {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0;

      @media (max-width: 990px) {
        flex: 1 1;
        margin-bottom: -1px;
      }

      &.active {
        &:after {
          opacity: 1;
        }

        background-color: #fff;

        .button {
          border-right: .1rem solid #fff;
          color: #5c6ac4;

          @media (max-width: 990px) {
            border-bottom: .1rem solid #fff;
          }
        }
      }

      @media (min-width: 991px) {
        &:nth-child(odd) {
          .button {
            border-top: .1rem solid #dfe3e8;
            border-bottom: .1rem solid #dfe3e8;
          }
        }
      }

      @media (max-width: 990px) {
        .button {
          border-top: .1rem solid #dfe3e8 !important;
          border-right: .1rem solid #dfe3e8 !important;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: .3rem;
        background: #5c6ac4;
        opacity: 0;

        @media (max-width: 990px) {
          width: 100%;
          height: 0.3rem;
        }
      }

      .button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
        background: none;
        border: none;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
        text-transform: none;
        letter-spacing: normal;
        color: #637381;
        position: relative;
        display: flex;
        align-items: center;
        text-align: left;
        width: 22rem;
        margin: 0 -1px;
        padding: 2.4rem;
        outline: none;
        text-decoration: none;
        cursor: pointer;
        border-right: .1rem solid #dfe3e8;
        border-left: .1rem solid #dfe3e8;

        @media (max-width: 990px) {
          font-size: 1.3rem;
          line-height: 2rem;
          display: block;
          text-align: center;
          margin: 0;
          border: 0;
          width: 100%;
          height: 100%;
          padding: 1.6rem;
        }

        .iconComponent {
          margin-right: 20px;

          @media (max-width: 990px) {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    min-height: 150px;
    border-top: .1rem solid #dfe3e8;
    border-right: .1rem solid #dfe3e8;
    border-bottom: .1rem solid #dfe3e8;
    padding: 2.1rem;
  }
}