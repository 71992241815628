.c-white {
  color: #FFF;
}

.c-amber-500 {
  color: #EDA609;
}

.c-green-500 {
  color: #7ECE1F;
}

.c-pink-500 {
  color: #FF6699;
}

.c-blue-500 {
  color: #3B7AEE;
}

.c-blue-light-500 {
  color: #1AC7C3;
}

.c-blue-light-900 {
  color: #05A2B1;
}

.c-teal-500 {
  color: #76c4b0;
}

.c-grey-300 {
  color: #9e9e9e
}

.c-grey-500 {
  color: #707070;
}


.c-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.bg-grey-200 {
  background-color: #e6e6e6;
}

.bg-amber-500 {
  background-color: #EDA609;
}

.bg-teal-500 {
  background-color:  #76c4b0;
}

.bg-green-500 {
  background-color: #7ECE1F;
}

.bg-pink-500 {
  background-color: #FF6699;
}

.bg-blue-500 {
  background-color: #3B7AEE;
}

.bg-blue-light-500 {
  background-color: #1AC7C3;
}

.bg-blue-light-900 {
  background-color: #05A2B1;
}

.bg-grey-300 {
  background-color: #9e9e9e
}

.bg-grey-500 {
  background-color: #707070;
}

.bg-red-500 {
  background-color: #FF0000;
}