.modal {
  border-radius: 10px;
  padding: 32px;
  background-color: white;
  text-align: center;
  overflow: hidden;
}

.title {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 1.2;
  color: #171c8e;
  font-family: BrandonGrotesque,sans-serif;
  font-weight: 700;
}

.subtitle {
  margin-bottom: 8px;
}

.productName {
  margin-top: 8px;
  font-weight: bold;
}

.description {
  word-break: break-word;
  margin-top: 8px;
}

.select {
  padding: 8px 28px 8px 10px;
  text-indent: 0.01px;
  width: 100%;
  font-size: 12px;
  display: block;
  border: 1px solid #e8e9eb;
  background-color: #fff;
}

.containerSelect {
  position: relative;
  width: 100%;
}

.containerProductImage {
  max-width: 340px;
  width: 50%;

  @media (max-width: 700px) {
    width: 90%;
  }
}

.productImage {
  width: 100%;
}

.containerTitle {
  margin-bottom: 20px;
}

.containerProduct {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
}

.containerPrice {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.price {
  color: #9ca0a6;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-decoration: line-through;
}

.discountPrice {
  margin-left: 10px;
  color: #e63235;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
}

.containerButtons {
  max-width: 340px;
  width: 100%;
  margin-left: 30px;

  @media (max-width: 700px) {
    max-width: 100%;
    margin-left: 0;
  }
}

.addToCart {
  margin-top: 8px;
  color: #fff;
  background-color: #e63235;
}

.cancel {
  margin-top: 16px;
  color: #9ca0a6;
  cursor: pointer;
  opacity: 0.8;
}

.btn {
  position: relative;
  vertical-align: middle;
  padding: 19px 35px;
  font-family: BrandonGrotesque,sans-serif;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 1px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(0,0,0,.06);
  transition: .3s cubic-bezier(.32,.36,.4,1);
}
