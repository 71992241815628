.AssetRow {
  padding: 3px 5px 3px 10px;
  cursor: pointer;

  &:hover {
    background-color: #B3BCF5;
  }

  &.selected {
    background-color: #B3BCF5;
  }
}