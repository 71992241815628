.DescriptionDemoBox {
  .title {
    font-family: Noto Sans TC,sans-serif;
    color: #333333;
    font-weight: 700;
    letter-spacing: -.5px;
    margin-bottom: 15px;
    font-size: 28px;
  }

  .subtitle {
    font-size: 14px;
    color: #27A3FC;
    font-family: Noto Sans TC,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .5px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  .description {
    margin-bottom: 20px;
  }

  .demoButton {
      background-color: #fff;
      border: solid 1px #27A3FC;
      border-radius: 3px;
      font-size: 16px;
      color: #27A3FC;
      font-weight: 500;
      padding: 10px 22px;
      text-transform: uppercase;
      letter-spacing: 0.9px;
      cursor: pointer;
      text-decoration: none;
      width: fit-content;
      transition: all 0.3s;

      &:hover {
        background-color: #27A3FC;
        color: #fff;

        .iconContainer {
          svg {
            fill: #fff;
          }
        }
      }

      .iconContainer {
        width: 15px;
        height: 20px;
        margin-left: 5px;

        svg {
          transition: all 0.3s;
          fill: #27A3FC;
        }
      }
    }

  .note {
    margin-top: 10px;
    font-style: italic;
    color: #AAA;
  }
}