.OfferForm {
  .badge {
    background-color: #EBECF0;
    border-radius: 2em;
    color: #172B4D;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 1px;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center;
  }

  .helpText {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-transform: none;
    text-transform: initial;
    letter-spacing: normal;
    letter-spacing: initial;
    color: #637381;
    margin-top: 0.4rem;
  }

  .Polaris-Card {
    overflow: visible;
  }
}