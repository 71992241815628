@import 'base/layout';
@import 'base/helper.scss';
@import 'base/colors.scss';
@import 'base/typography.scss';
@import 'base/hint.scss';
@import 'base/components.scss';
@import 'base/animations.scss';

body {
  margin: 0;
  padding: 0;
}
