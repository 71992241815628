.TextFieldWithColor {

  > div:first-child {
    width: 100%;
    max-width: 400px;
  }

  .ColorPicker {
    margin-top: 25px;
    margin-left: 5px;
  }
}
