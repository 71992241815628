.ColorPickerWithFont {
  .selectContainer {
    &.hint--top {
      &:after {
        left: -5%;
      }
    }
  }

  .fontStyleSelect {
    min-width: 100px;
  }

  .fontSizeSelect {
    min-width: 75px;
  }
}