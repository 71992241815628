.StatsSection {
  background-image: url('/assets/images/waves.svg');
  background-size: cover;
  height: 400px;
  margin-top: 80px;

  @media (max-width: 767px) {
    height: 480px;
  }

  .title {
    font-family: Noto Sans TC,sans-serif;
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    text-shadow: 0 0 10px rgba(0,0,0,.2);
  }

  .container {
    padding: 20px;
    display: flex;
    justify-content: center;
  }

  .statsContainer {
    padding: 20px;

    .statContent {
      text-align: center;
      padding: 5px 32px;

      .statNumber {
        color: #fff;
        font-size: 5rem;
        line-height: 1;
        font-weight: 900;
        letter-spacing: -0.5px;

        @media (max-width: 767px) {
          font-size: 4rem;
        }
      }

      .label {
        color: #fff;
        font-weight: 400;
        font-size: 18px;
      }
    }
  }

  .StartFreeTrialButton {
    @media (max-width: 767px) {
      font-size: 16px;
      padding: 10px;
    }
  }
}