.StripePlanItem {
  .name {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 2px;
    color: #637381;
  }

  .price {
    font-weight: normal;
    font-size: 32px;
    line-height: 1.222;
  }

  .description {

    &--item {
      line-height: 1.5;
      width: fit-content;
    }
  }

  .trialDays {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 2px;
    color: #637381;
  }
}