body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
