.container {
  width: 100%;
  max-width: 240px;
  background-color: #fff;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.productImage {
  width: 100%;
  height: 190px;
  object-fit: contain;
}

.productName {
  text-align: center;
  margin-top: 5px;
}

.modalProductName {
  text-align: center;
  margin-top: 10px;
  font-size: 18px !important;
}

.priceContainer {
  text-align: center;
  font-weight: bold;
  margin: 5px 0;
}

.currentPrice {
  text-decoration: line-through;
  color: #000;
}

.discountedPrice {
  color: #DE3618;
  margin-left: 4px;
}

.select {
  padding: 8px 28px 8px 10px;
  text-indent: 0.01px;
  width: 100%;
  font-size: 12px;
  display: block;
  border: 1px solid #e8e9eb;
  background-color: #fff;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  appearance: none;
}

.containerSelect {
  position: relative;
  width: 100%;
}

.link {
  color: inherit;
  text-decoration: inherit;
}

.addToOrder {
  margin-top: 8px;
  color: #fff;
  background-color: #e63235;
}

.loading {
  opacity: 0.6;
}

.moreInfo {
  margin-top: 8px;
  border: solid 1px #dedede !important;
}

.btn {
  position: relative;
  vertical-align: middle;
  padding: 14px 35px;
  font-family: BrandonGrotesque,sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(0,0,0,.06);
  transition: .3s cubic-bezier(.32,.36,.4,1);
}

.modalContent {
  padding: 20px;
}

.bodyHtmlContent {
  margin: 20px 0;
  text-align: center;
}
