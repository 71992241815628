.Footer {
  background-color: #0E3B5C;
  padding: 10px;
  font-size: 14px;
  color: #ddd;

  .EmailContactButton {
    button {
      background: transparent;
      border-color: #fff;
      box-shadow: none;
      color: white;
      transition: all 0.3s;

      .Polaris-Button__Text {
        font-weight: 400;
        text-transform: uppercase;
      }

      &:hover {
        background-color: #fff;
        color: #333;
      }
    }
  }

  .privacyLink {
    color: #fff;

    &:visited, &:focus, &:focus-within, &:active {
      color: #fff;
    }

    &:hover {
      color: #fff;
      opacity: 0.6;
    }
  }
}
