@media (min-width: 769px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

.w100 {
  width: 100%;
}
.mw100 {
  max-width: 100%;
}

.borderBox {
  box-sizing: border-box;
}

.full-height {
  height: 100%;
  height: -moz-available;          /* WebKit-based browsers will ignore this. */
  height: stretch;
}

.full-width {
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: stretch;
}

/*
Flex
*/

.flex-row {
  flex-direction: row;
  justify-content: space-around;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flex-1 {
  flex: 1;
}
.no-shrink {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.flex-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.flex-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.flex-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-justify {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-content-center {
  justify-content: center;
}
.flex-content-end {
  justify-content: flex-end;
}
.flex-content-start {
  justify-content: flex-start;
}
.flex-content-around {
  justify-content: space-around;
}
.flex-content-between {
  justify-content: space-between;
}

.full-flex-center {
  @extend .flex;
  @extend .flex-center;
  @extend .flex-justify;
  @extend .flex-content-center;
  align-content: center;
}

@media (max-width: 40em) {
  .sm-flex-content-center {
    justify-content: center;
  }
}

/*
Flex Component

*/
.flex-auto {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}
.flex-grow {
  flex-grow: 1;
}

/*
Responsive


.sm-flex    - :min-width: 40em
.md-flex    - :min-width: 52em
.lg-flex    - :min-width: 64em

*/
@media (min-width: 40em) {
  .sm-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 52em) {
  .md-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 64em) {
  .lg-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

/*
Float

Don't forget to use '.clearfix' to clear your float

.left    - :
.right    - :
*/
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.clearfix:after {
  clear: both;
}

.left {
  float: left;
}
.right {
  float: right;
}

/*
Position
*/

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

.sticky {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}

/*
Display
*/
.inline {
  display: inline;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline-flex {
  display: inline-flex;
}

/*
Col

You can use [sm-, md-, lg-] before for make it responsive.

"sm-" for min-width: 40em

"md-" for min-width: 52em

"lg-" for min-width: 64em

.full-height    - :
.col-1 - 8.33333%
.col-2 - 16.66667%
.col-3 - 25%
.col-4 - 33.33333%
.col-5 - 41.66667%
.col-6 - 50%
.col-7 - 58.33333%
.col-8 - 66.66667%
.col-9 - 75%
.col-10 - 83.33333%
.col-11 - 91.66667%
.col-12 - 100%

*/

.col-1 {
  width: 8.33333%;
}

.col-2 {
  width: 16.66667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33333%;
}

.col-5 {
  width: 41.66667%;
}

.col-5-2 {
  width: 49%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33333%;
}

.col-8 {
  width: 66.66667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33333%;
}

.col-11 {
  width: 91.66667%;
}

.col-12 {
  width: 100%;
}

@media (min-width: 40em) {
  .sm-col {
    float: left;
    box-sizing: border-box;
  }

  .sm-col-right {
    float: right;
    box-sizing: border-box;
  }

  .sm-col-1 {
    width: 8.33333%;
  }

  .sm-col-2 {
    width: 16.66667%;
  }

  .sm-col-3 {
    width: 25%;
  }

  .sm-col-4 {
    width: 33.33333%;
  }

  .sm-col-5 {
    width: 41.66667%;
  }

  .sm-col-6 {
    width: 50%;
  }

  .sm-col-7 {
    width: 58.33333%;
  }

  .sm-col-8 {
    width: 66.66667%;
  }

  .sm-col-9 {
    width: 75%;
  }

  .sm-col-10 {
    width: 83.33333%;
  }

  .sm-col-11 {
    width: 91.66667%;
  }

  .sm-col-12 {
    width: 100%;
  }
}
@media (min-width: 52em) {
  .started--card {
    margin-top: 0;
  }

  .md-col {
    float: left;
    box-sizing: border-box;
  }

  .md-col-right {
    float: right;
    box-sizing: border-box;
  }

  .md-col-1 {
    width: 8.33333%;
  }

  .md-col-2 {
    width: 16.66667%;
  }

  .md-col-3 {
    width: 25%;
  }

  .md-col-4 {
    width: 33.33333%;
  }

  .md-col-5 {
    width: 41.66667%;
  }

  .md-col-5-2 {
    width: 49%;
  }

  .md-col-6 {
    width: 50%;
  }

  .md-col-7 {
    width: 58.33333%;
  }

  .md-col-8 {
    width: 66.66667%;
  }

  .md-col-9 {
    width: 75%;
  }

  .md-col-10 {
    width: 83.33333%;
  }

  .md-col-11 {
    width: 91.66667%;
  }

  .md-col-12 {
    width: 100%;
  }
}
@media (min-width: 64em) {
  .lg-col {
    float: left;
    box-sizing: border-box;
  }

  .lg-col-right {
    float: right;
    box-sizing: border-box;
  }

  .lg-col-1 {
    width: 8.33333%;
  }

  .lg-col-2 {
    width: 16.66667%;
  }

  .lg-col-3 {
    width: 25%;
  }

  .lg-col-4 {
    width: 33.33333%;
  }

  .lg-col-5 {
    width: 41.66667%;
  }

  .lg-col-6 {
    width: 50%;
  }

  .lg-col-7 {
    width: 58.33333%;
  }

  .lg-col-8 {
    width: 66.66667%;
  }

  .lg-col-9 {
    width: 75%;
  }

  .lg-col-10 {
    width: 83.33333%;
  }

  .lg-col-11 {
    width: 91.66667%;
  }

  .lg-col-12 {
    width: 100%;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

